import { render, staticRenderFns } from "./SwapList.vue?vue&type=template&id=3bcc8592&scoped=true"
import script from "./SwapList.vue?vue&type=script&lang=js"
export * from "./SwapList.vue?vue&type=script&lang=js"
import style0 from "./SwapList.vue?vue&type=style&index=0&id=3bcc8592&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bcc8592",
  null
  
)

export default component.exports