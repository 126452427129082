var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-propositions"},[_c('div',{staticClass:"page-container"},[(!_vm.ready || _vm.loadingPropositions)?_c('div',{staticClass:"propositions-container"},[_c('h2',{staticClass:"page-heading"},[_vm._v(_vm._s(_vm.$t('my_propositions_loading')))])]):_c('div',{staticClass:"propositions-container"},[_c('h2',{staticClass:"page-heading"},[_vm._v(" "+_vm._s(_vm.$t('menu_items_link_3'))+" ")]),(_vm.shouldShowOnboardingBanner)?_c('div',{staticClass:"onboarding-wrapper"},[_c('OnboardingBanner')],1):_vm._e(),(
          (!_vm.activePropositions.length > 0 &&
            (_vm.activePropositions.length === 0 ||
              _vm.displayedOutdatedTrial !== null)) ||
          _vm.propositions.length === 0
        )?_c('NoActivePropositionInfo',{attrs:{"info-type":_vm.propositionInfoType}}):_vm._e(),(_vm.propositions.length > 0)?[_c('div',{staticClass:"my-propositions-card"},[_c('MyPropositionsCard',{attrs:{"proposition":_vm.currentlyEditingProposition}})],1),_c('div',{staticClass:"in-swap-process-toggle"},[_c('BaseCheckbox',{staticClass:"checkbox",attrs:{"id":"inSwapProcess","label":_vm.$t('edit_profile_in_swap_process'),"value":_vm.inSwapProcess,"selected":_vm.inSwapProcess,"is-checkbox-dark":true,"variant":"label-light","size":"sm"},on:{"change":_vm.toggleInSwapProcess}})],1),(_vm.activePropositions.length > 1)?_c('PropositionSwitcher',{staticClass:"prop-switcher"}):_vm._e(),_c('div',{staticClass:"proposition-links-container"},[(_vm.propositions.length > 1)?_c('PropositionEditSwitcher',{staticClass:"prop-edit-switcher"}):_vm._e(),_c('LinkList',{staticClass:"proposition-links"},[_c('LinkListItem',{attrs:{"path":_vm.$t('path_edit_proposition', _vm.$routeLocale) +
                '/' +
                _vm.currentlyEditingPropositionId,"icon-file":'pen'}},[_vm._v(_vm._s(_vm.$t('my_propositions_link_edit')))]),_c('LinkListItem',{attrs:{"path":_vm.$t('path_edit_proposition_wishes', _vm.$routeLocale) +
                '/' +
                _vm.currentlyEditingPropositionId,"icon-file":'map-pin'}},[_vm._v(_vm._s(_vm.$t('my_propositions_link_wish')))]),(_vm.hasSecondWish)?_c('LinkListItem',{attrs:{"path":_vm.$t('path_edit_proposition_second_wishes', _vm.$routeLocale) +
                '/' +
                _vm.currentlyEditingPropositionId,"icon-file":'map-pin'}},[_vm._v(" "+_vm._s(_vm.$t('my_propositions_link_second_wish'))+" ")]):_vm._e(),_c('LinkListItem',{attrs:{"path":_vm.$t('path_handle_subscription', _vm.$routeLocale) +
                '/' +
                _vm.currentlyEditingPropositionId,"icon-file":'credit-card'}},[_vm._v(_vm._s(_vm.$t('my_propositions_link_payments')))]),_c('div',{staticClass:"my-visitors-link-container",on:{"click":_vm.handleShowMyVisitorsPaywall}},[_c('LinkListItem',{attrs:{"path":_vm.currentlyEditingProposition.active &&
                  !_vm.currentlyEditingProposition.isTrial &&
                  !_vm.currentlyEditingProposition.isFreemium
                    ? _vm.$t('path_my_visitors', _vm.$routeLocale) +
                      '/' +
                      _vm.currentlyEditingPropositionId
                    : '',"icon-file":'user-search'}},[_vm._v(_vm._s(_vm.$t('my_propositions_link_visitors')))])],1),_c('LinkListItem',{attrs:{"path":_vm.$routes.BLOCKED_PROPOSITIONS,"icon-file":'blocked-house'}},[_vm._v(_vm._s(_vm.$t('side_menu_removed_swap_suggestions')))]),(
                _vm.$country.isFeatureEnabled('EXTRA_VISIBILITY') &&
                _vm.currentlyEditingProposition.active &&
                !_vm.currentlyEditingProposition.isFreemium &&
                !_vm.currentlyEditingProposition.isTrial &&
                !_vm.currentlyEditingProposition.extraVisibility
              )?_c('LinkListItem',{staticClass:"boost-list-item",attrs:{"icon-file":'stars-white2'},nativeOn:{"click":function($event){_vm.setExtraVisibilityPaymentProposition(
                  _vm.currentlyEditingProposition
                );
                _vm.setExtraVisibilityPaymentVisible(true);}},scopedSlots:_vm._u([{key:"icon-after",fn:function(){return [_c('BaseIcon',{staticClass:"chevron-icon",attrs:{"icon-file":'chevron-right-white2',"width":20,"height":20}})]},proxy:true}],null,false,795199410)},[_vm._v(_vm._s(_vm.$t('boost_cta_button'))+" ")]):_vm._e()],1)],1)]:_vm._e(),_c('LinkList',{staticClass:"new-and-delete-links",attrs:{"compact":true}},[_c('LinkListItem',{attrs:{"path":_vm.$t('path_add_proposition', _vm.$routeLocale),"is-external":true,"icon-file":'plusminus'}},[_vm._v(_vm._s(_vm.$t('my_propositions_link_new')))]),(_vm.activePropositions.length > 0)?_c('LinkListItem',{attrs:{"path":_vm.$t('path_handle_subscription', _vm.$routeLocale) +
            '/' +
            _vm.currentlyEditingPropositionId,"icon-file":'trash-bin'}},[_vm._v(" "+_vm._s(_vm.$t('my_propositions_link_cancel'))+" ")]):_vm._e()],1),(!_vm.isAppRequest)?_c('div',{staticClass:"log-out"},[_c('router-link',{attrs:{"to":_vm.$routes.LOGOUT}},[_vm._v(_vm._s(_vm.$t('popup_menu_item_8')))])],1):_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }