<template>
  <div class="mobile-swap-header">
    <div
      :class="
        tutStatus === TUTORIAL_STATUS_TYPES.STARTED &&
        tutActiveStep === TUTORIAL_STEPS_TYPES.FIRST_INTEREST_MODAL
          ? 'animate-slide-down-height'
          : ''
      "
    >
      <InfoBanner class="info-banner" />

      <PropositionSwitcher v-if="isUserList && showPropositionSwitcher" />

      <div class="action-wrapper">
        <div class="swap-count-wrapper">
          <span v-if="loading">
            {{ $t('mobile_header_loading_swaps') }}
          </span>
          <span v-else-if="isLastPartFilterType && totalFilterCount > 0">
            {{
              $t('mobile_header_showing_swap_interest', {
                total: postFilterCountTotal
              })
            }}
          </span>
          <span v-else-if="undistributedCount">
            {{
              $t('mobile_header_showing_undistributed', {
                total: postFilterCountTotal
              })
            }}
          </span>
          <span v-else>
            {{ $t('mobile_header_showing') }}
            {{ postFilterCountTotal }}
            {{
              postFilterCountTotal < totalFilterCount && totalFilterCount > 0
                ? ` ${$t('swaplist_header_of')} ${totalFilterCount}`
                : ''
            }}
            {{ $t('mobile_header_swaps') }}
          </span>
        </div>
      </div>

      <!-- <SwapListToggle
        v-if="isUserList && !matchesViewABTest"
      /> -->

      <!-- <div class="search-filter-wrapper">
        <Search />
        <div class="filter-action" @click.stop="openFilterModal">
          <div class="filter-icon" />
          <div v-if="filterCount > 0" class="filter-count">
            {{ filterCount }}
          </div>
        </div>
      </div> -->

      <!-- <div v-if="loading" class="action-wrapper"> -->
      <!-- <div v-if="loading" class="swap-count-wrapper">
          {{ $t('mobile_header_loading_swaps') }}
        </div> -->
      <!-- <div
          v-if="newSwapsCount > 0"
          class="swap-count-wrapper"
        >
          <span v-if="newSwapsCount === 1">
            {{ $t('swaplist_header_new_count_single_header') }}</span
          >
          <span v-if="newSwapsCount > 1">
            {{
              $t('swaplist_header_new_count_header', {
                new: newSwapsCount
              })
            }}
          </span>
        </div> -->
      <!-- <div v-else class="swap-count-wrapper">
          {{ $t('mobile_header_showing') }}
          <span class="bold">{{ postFilterCountTotal }}</span>
          {{ $t('mobile_header_swaps') }}

          <div
            v-if="swapFilterType == 'ALL' || swapFilterType == 'NEW'"
            class="swap-count-unanswered"
          >
            <span class="bold">{{ postFilterCountUnmarked }}</span>
            {{ $t('mobile_header_unanswered') }}
          </div>
        </div> -->
      <!-- <ListTypeToggle /> -->
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  SORT_TYPES,
  SWAP_LIST_TYPES,
  SWAP_FILTER_TYPES
} from '../../store/modules/swapList';

// import Search from './Search';
import PropositionSwitcher from './PropositionSwitcher';
// import SwapListToggle from './SwapListToggle';
// import ListTypeToggle from './ListTypeToggle';
import InfoBanner from '@/components/InfoBanner';
import {
  TUTORIAL_STATUS_TYPES,
  TUTORIAL_STEPS_TYPES
} from '@/store/modules/tutorial';

export default {
  name: 'MobileSwapHeader',

  components: {
    // Search,
    PropositionSwitcher,
    // SwapListToggle,
    // ListTypeToggle,
    InfoBanner
  },

  data() {
    return {
      SWAP_LIST_TYPES,
      SWAP_FILTER_TYPES,
      SORT_TYPES,
      TUTORIAL_STATUS_TYPES,
      TUTORIAL_STEPS_TYPES
    };
  },

  computed: {
    ...mapGetters({
      isUserList: 'swapList/isUserList',
      propositions: 'myPropositions/propositions',
      sortType: 'swapList/sortType',
      filterCount: 'swapList/filterCount',
      postFilterCountTotal: 'swapList/postFilterCountTotal',
      postFilterCountUnmarked: 'swapList/postFilterCountUnmarked',
      loading: 'swapList/loading',
      accountType: 'myPropositions/accountType',
      newSwapsCount: 'swapList/newSwapsCount',
      swapFilterType: 'swapList/swapFilterType',
      tutStatus: 'tutorial/status',
      tutActiveStep: 'tutorial/activeStep',
      unansweredSwaps: 'swapList/unansweredSwaps',
      answeredSwaps: 'swapList/answeredSwaps',
      removedSwaps: 'swapList/removedSwaps',
      swaps: 'swapList/swaps',
      undistributedCount: 'swapList/undistributedCount'
    }),

    totalFilterCount() {
      switch (this.swapFilterType) {
        case SWAP_FILTER_TYPES.UNANSWERED:
          return this.unansweredSwaps;
        case SWAP_FILTER_TYPES.ANSWERED:
          return this.answeredSwaps;
        case SWAP_FILTER_TYPES.REMOVED:
          return this.removedSwaps;
        default:
          return this.postFilterCountTotal;
      }
    },

    // matchesViewABTest() {
    //   return this.$growthbook.isFeatureFlagEnabled('matches-view');
    // },

    activePropositions() {
      return this.propositions.filter(p => p.active);
    },

    showPropositionSwitcher() {
      return this.activePropositions && this.activePropositions.length > 1;
    },

    isLastPartFilterType() {
      return this.swapFilterType === SWAP_FILTER_TYPES.LAST_PART_NEW;
    },

    hasUndistributedSwaps() {
      return this.swaps.length > 0 && this.undistributedCount > 0;
    }
  },

  methods: {
    ...mapActions({
      setSortType: 'swapList/setSortType',
      resetFilters: 'swapList/resetFilters',
      fetchPropositions: 'myPropositions/fetchPropositions'
    }),

    handleSelectSortType(sortOption) {
      this.setSortType({ type: sortOption });
      this.closeSortModal();
    },

    checkIfNeedsFetchPropositions() {
      if (this.propositions.length === 0) {
        this.fetchPropositions();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.mobile-swap-header {
  animation: slide-down 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-delay: 0s;
  background-color: #f1f2f6;

  @media ($desktop) {
    padding-top: 10px;
  }
}

.search-filter-wrapper {
  display: flex;
  padding: 10px;
}

.bold {
  font-weight: 700;
}

.filter-action {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 14px;
  padding-right: 4px;
  font-size: 0.8rem;
  cursor: pointer;
}

.filter-icon {
  height: 22px;
  width: 22px;
  background-image: url('../../assets/svg/filter2.svg');
  background-position: center;
  background-size: contain;
}

.filter-count {
  border-radius: 50%;
  background-color: #213a75;
  color: white;
  font-size: 0.7rem;
  font-weight: 700;
  margin-left: 8px;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1px;
  margin-top: -1px;
}

.action-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px;
  padding: 20px 0 0 5px;
}

.map-btn-wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;

  @media ($desktop) {
    margin-left: 24px;
  }
}

.swap-count-wrapper {
  display: flex;
  width: 100%;
  position: relative;
  font-weight: 600;
  font-size: 0.8rem;
}

.swap-count-unanswered {
  color: #878b9d;
}

.info-banner {
  margin: 10px;
  margin-top: 0;
  top: 10px;
}

@keyframes fade-grow-in {
  0% {
    opacity: 0;
    transform: scale(0.8);
    transform-origin: 50% 0%;
  }
  100% {
    opacity: 1;
    transform: scale(1);
    transform-origin: 50% 0%;
  }
}

.animate-slide-down-height {
  animation: slide-down-height 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-delay: 0.4s;
}

@keyframes slide-down-height {
  0% {
    height: 0;
    opacity: 0;
  }
  20% {
    transform: translateY(10px);
  }
  80% {
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    height: 140px;
  }
}
</style>
