<template>
  <div class="edit-profile-container">
    <div class="edit-profile">
      <div class="profile-image-container">
        <div class="profile-image-ratio">
          <BaseImage v-if="user.avatar" :url="user.avatar" />
        </div>
        <div class="icon-container icon-container-camera">
          <input
            ref="fileInput"
            type="file"
            accept="image/*"
            @change="handleFileInputChange($event.target.files)"
          />
          <BaseIcon icon="camera-white" class="upload-photos-icon" />
        </div>
        <div
          class="icon-container icon-container-trash"
          @click="handleRemovePhotoClick()"
        >
          <BaseIcon icon="trash-alt" class="trash-icon" />
        </div>
      </div>

      <h4 class="h4">{{ $t('edit_profile_your_profile') }}</h4>

      <BaseInput
        id="name"
        v-model="localUser.name"
        name="name"
        type="text"
        :label="$t('edit_profile_name_label')"
        :placeholder="$t('edit_profile_name_label')"
        :valid="isNameValid"
        :error-message="$t('edit_profile_name_error')"
        class="base-input-alt"
        @input="handleInputChange"
      />

      <p>{{ $t('edit_profile_info') }}</p>

      <hr />

      <h4 class="h4">{{ $t('edit_user_credentials_email') }}</h4>

      <BaseInput
        id="email"
        v-model="localUser.email"
        name="email"
        type="email"
        :label="$t('edit_user_credentials_email_label')"
        :placeholder="$t('edit_user_credentials_email_label')"
        icon="envelope"
        :valid="!someValueHasBeenChanged || isEmailValid"
        :error-message="$t('edit_user_credentials_email_error')"
        class="base-input-alt"
        @input="handleInputChange"
      />

      <hr />

      <template>
        <h4 class="h4">{{ $t('edit_user_credentials_change_password') }}</h4>

        <BaseInput
          id="oldPassword"
          v-model="localUser.oldPassword"
          name="oldPassword"
          type="password"
          :label="$t('edit_user_credentials_old_password')"
          :placeholder="$t('edit_user_credentials_old_password')"
          icon="lock"
          :has-show-password="true"
          class="base-input-alt"
          @input="handleInputChange"
        />
        <BaseInput
          id="newPassword"
          v-model="localUser.newPassword"
          name="newPassword"
          type="password"
          :label="$t('edit_user_credentials_new_password')"
          :placeholder="$t('edit_user_credentials_new_password')"
          icon="lock"
          :has-show-password="true"
          class="base-input-alt"
          @input="handleInputChange"
        />

        <p class="input-info">{{ $t('edit_user_credentials_pw_info') }}</p>
      </template>

      <hr />

      <section
        v-if="$country.isFeatureEnabled('BANKID')"
        class="bankid-section"
      >
        <template v-if="!user.verified">
          <h4 class="h4 bankid-title">
            Verifiera dig med BankID
            <BaseIcon
              icon="verified"
              :width="20"
              :height="20"
              class="bankid-badge"
            />
          </h4>
          <p>
            Verifiera ditt konto med BankID så ökar du din annons trovärdighet
            och får möjligheten att logga in med BankID.
          </p>

          <br />

          <BaseButton
            class="full-width"
            icon="bankid-white"
            @click="bankIdVerificationVisible = true"
          >
            Verifiera dig här
          </BaseButton>

          <BaseModal
            v-if="bankIdVerificationVisible && !user.verified"
            title="Verifiera konto med BankID"
            @close="bankIdVerificationVisible = false"
          >
            <BankId
              :collect-path="'/verification/bankid'"
              :auth-path="'/bankid/start'"
              :qr-path="'/bankid/qr'"
              :cancel-path="'/bankid/cancel'"
              :base-url="baseUrl"
              @success="handleBankidSuccess($event)"
            ></BankId>
          </BaseModal>
        </template>
        <template v-else>
          <h4 class="h4 bankid-title">
            Verifierad med BankID
            <BaseIcon
              icon="verified"
              :width="20"
              :height="20"
              class="bankid-badge"
            />
          </h4>
          <p>
            Du kan logga in med BankID, men har också ökat profilens
            trovärdighet.
          </p>
        </template>

        <hr />
      </section>

      <h4 class="h4">{{ $t('edit_profile_notifications') }}</h4>

      <BaseCheckbox
        id="interest"
        :label="$t('edit_profile_new_swap')"
        :value="localUser.emailNotifications.onMatch"
        :selected="localUser.emailNotifications.onMatch"
        :is-checkbox-dark="true"
        class="checkbox"
        @change="handleInterestToggle"
      />
      <BaseCheckbox
        id="unreadMessages"
        :label="$t('edit_profile_new_message')"
        :value="localUser.emailNotifications.onMessage"
        :selected="localUser.emailNotifications.onMessage"
        :is-checkbox-dark="true"
        class="checkbox"
        @change="handleUnreadMessagesToggle"
      />
      <BaseCheckbox
        id="lastPart"
        :label="$t('edit_profile_on_last_part')"
        :value="localUser.emailNotifications.onLastPart"
        :selected="localUser.emailNotifications.onLastPart"
        :is-checkbox-dark="true"
        class="checkbox"
        @change="handleLastPartToggle"
      />

      <hr />

      <div class="remove-account-container">
        <h4 class="h4">{{ $t('edit_profile_handle_account') }}</h4>
        <button class="delete-account-btn" @click="handleRemoveAccountClick()">
          <p>
            {{ $t('edit_profile_remove_account') }}
          </p>
        </button>
      </div>

      <BaseModal
        v-if="showRemoveImageModal"
        :title="$t('edit_profile_remove_picture')"
        @close="showRemoveImageModal = false"
      >
        <div class="remove-modal-content">
          <BaseButton
            icon="trash-alt"
            class="warning"
            @click="handleConfirmRemoveImage()"
          >
            {{ $t('edit_profile_remove') }}
          </BaseButton>
          <BaseButton
            class="grey outline"
            @click="showRemoveImageModal = false"
          >
            {{ $t('edit_profile_close') }}
          </BaseButton>
        </div>
      </BaseModal>
    </div>

    <!-- Cancel Account modal -->
    <stepper-modal
      v-if="cancelModalOpen"
      :close-function="closeCancelModal"
      :no-shadow="true"
    >
      <cancel-subscription
        slot="content"
        :close-function="closeCancelModal"
        :is-delete-account="true"
      />
    </stepper-modal>

    <FloatingSaveButton
      class="save-button"
      :is-visible="someValueHasBeenChanged && isNameValid && isEmailValid"
      :icon-file="'check-mark-white'"
      @saveClick="handleSaveClick"
    >
      <template>
        <template v-if="isSaving">
          {{ $t('edit_user_credentials_loading') }}</template
        >
        <template v-else>
          {{ $t('edit_user_credentials_save') }}
        </template>
      </template>
    </FloatingSaveButton>
  </div>
</template>

<script>
import { lbApiBeta } from '../../utils/axiosConfig';
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import { getBetaEndpoint } from '@/utils/getEndpoint';
import BankId from '@/components/BankId/BankId';
import { getAuthenticationEndpoint } from '../../utils/getEndpoint';
import CancelSubscription from '../../components/HandleSubscription/CancelSubscription';
import StepperModal from '../../components/Modals/StepperModal';
import formValidations from '@/utils/formValidations';
import FloatingSaveButton from '../../components/Buttons/FloatingSaveButton.vue';

export default {
  name: 'EditProfile',

  components: {
    BankId,
    CancelSubscription,
    StepperModal,
    FloatingSaveButton
  },

  data() {
    return {
      localUser: {
        avatar: '',
        name: '',
        email: '',
        oldPassword: '',
        newPassword: '',
        emailNotifications: {
          onMatch: false,
          onMessage: false,
          onLastPart: false
        }
      },
      showRemoveImageModal: false,
      showRemoveAccountModal: false,
      someValueHasBeenChanged: false,
      isSaving: false,
      errorMessage: '',
      bankIdVerificationVisible: false,
      baseUrl: getAuthenticationEndpoint()
    };
  },

  head: {
    title() {
      return {
        inner: this.$t('meta_title_edit_profile')
      };
    }
  },

  computed: {
    ...mapGetters({
      user: 'app/user',
      cancelModalOpen: 'handleSubscription/cancelModalOpen'
    }),
    isNameValid() {
      return !this.someValueHasBeenChanged || this.localUser.name.length > 0;
    },
    isEmailValid() {
      return formValidations.email(this.localUser.email);
    },
    hasEmailChanged() {
      return this.currentEmail !== this.localUser.email;
    }
  },

  created() {
    this.getUserData();

    window.scrollTo(0, 0);
  },

  methods: {
    ...mapActions({
      setToast: 'toast/setToast',
      updateUser: 'app/updateUser',
      updatedProfilePic: 'gtm/updatedProfilePic',
      openCancelModal: 'handleSubscription/openCancelModal',
      closeCancelModal: 'handleSubscription/closeCancelModal'
    }),
    goToEditUserCredentials() {
      this.$router.push(this.$routes.EDIT_USER_CREDENTIALS);
    },
    async handleBankidSuccess() {
      await this.updateUser({ verified: true });
    },
    async handleFileInputChange(files) {
      const URL = getBetaEndpoint() + '/api/editprofile/profile-picture';

      const form = new FormData();
      form.append('picture', files[0]);

      const config = {
        header: {
          'Content-Type': 'multipart/form-data'
        },
        withCredentials: true,
        credentials: 'same-origin'
      };

      await axios
        .post(URL, form, config)
        .then(() => {
          this.setToast({ title: this.$t('toast_profile_image_added') });

          this.updateUser({
            avatar: this.localUser.avatar + '?_=' + this.currentDate()
          });

          this.$forceUpdate();
          this.updatedProfilePic();
        })
        .catch(() => {
          this.setToast({
            title: this.$t('toast_general_error_message'),
            timer: 5
          });
        });

      this.$refs.fileInput.value = null;
    },
    handleRemovePhotoClick() {
      this.showRemoveImageModal = true;
    },
    async handleConfirmRemoveImage() {
      this.showRemoveImageModal = false;
      await lbApiBeta.delete('/api/editprofile/profile-picture').then(() => {
        this.setToast({ title: this.$t('toast_profile_image_removed') });

        this.getUserData();

        this.updateUser({
          avatar: this.localUser.avatar
        });
      });
    },
    handleRemoveAccountClick() {
      this.openCancelModal({ changeSubscription: false });
    },
    handleInterestToggle() {
      this.localUser.emailNotifications.onMatch =
        !this.localUser.emailNotifications.onMatch;
      this.someValueHasBeenChanged = true;
    },
    handleUnreadMessagesToggle() {
      this.localUser.emailNotifications.onMessage =
        !this.localUser.emailNotifications.onMessage;
      this.someValueHasBeenChanged = true;
    },
    handleLastPartToggle() {
      this.localUser.emailNotifications.onLastPart =
        !this.localUser.emailNotifications.onLastPart;
      this.someValueHasBeenChanged = true;
    },
    async handleSaveClick() {
      this.isSaving = true;
      try {
        const response = await lbApiBeta.put('/api/user/edit', this.localUser);
        const data = response.data;

        if (data.error === 'WRONG_PASSWORD') {
          this.setToast({
            title: this.$t('login_reset_new_password_error'),
            timer: 10
          });
        } else {
          this.setToast({ title: this.$t('toast_changes_saved') });
        }

        this.updateUser({
          name: this.localUser.name,
          phone: this.localUser.phone,
          email: this.localUser.email
        });

        this.someValueHasBeenChanged = false;
      } catch (error) {
        console.error('Error:', error);

        this.setToast({
          title: this.$t('toast_general_error_message'),
          timer: 5
        });
      } finally {
        this.localUser.newPassword = '';
        this.localUser.oldPassword = '';
        this.isSaving = false;
      }
    },
    async updateEmail() {
      const newEmail = this.localUser.email.trim();
      const { data } = await lbApiBeta.put('/api/user/edit', {
        email: newEmail
      });

      if (!data.success) {
        if (data.error === 'EMAIL_EXISTS') {
          this.setToast({
            title: this.$t('edit_user_credentials_error_email_exists'),
            timer: 20
          });
        } else {
          this.setToast({
            title: this.$t('toast_general_error_message')
          });
        }

        return false;
      }

      this.currentEmail = this.updateUser({
        email: newEmail
      });
      this.currentEmail = newEmail;

      return true;
    },
    handleInputChange() {
      this.someValueHasBeenChanged = true;
    },
    async getUserData() {
      const response = await lbApiBeta.get('/api/user/edit');

      if (response.status !== 200) {
        return;
      }

      this.localUser = response.data;
      this.currentEmail = this.localUser.email;
    },
    currentDate() {
      return new Date().getTime();
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-profile-container {
  max-width: none;
  width: 100%;
  background-color: #f7f7f9;
  padding-bottom: 150px;

  @media ($desktop) {
    padding-top: 90px;
  }
}

.edit-profile {
  max-width: 400px;
  width: 90%;
  margin: 0 auto;

  @media ($small-desktop) {
    width: 96%;
  }
}

.tab-navigation {
  @media ($mobile) {
    margin: 0 -20px;
    width: calc(100% + 40px);
  }
}

.profile-image-container {
  position: relative;
  margin: 50px 70px;

  @media ($desktop) {
    margin: 50px 100px;
  }
}

.profile-image-ratio {
  position: relative;
  padding-bottom: 100%;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}

.icon-container {
  display: grid;
  place-items: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 0;
  cursor: pointer;

  &[disabled] {
    opacity: 0.5;
  }
}

.icon-container-camera {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #409fff;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    font-size: 0;
    z-index: 1;
  }
}

.icon-container-trash {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #ff3843;
}

.checkbox {
  margin-bottom: 1rem;
}

.base-input-alt {
  margin-bottom: 24px !important;
}

p {
  margin-top: 0;
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
}

.remove-modal-content,
.remove-account-modal-content {
  display: flex;
  justify-content: center;
  padding: 10px 0;

  button {
    margin: 0 10px;
    white-space: nowrap;
  }
}

.remove-account-modal-content {
  margin-top: 10px;
}

.delete-account-btn {
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  cursor: pointer;

  p {
    color: red;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.bankid-badge {
  margin-left: 5px;
}

.bankid-title {
  display: inline-flex;
  align-items: center;
  margin-top: 0;
}

.save-button {
  width: 100%;
  padding: 0;

  @media ($desktop) {
    margin-top: 24px;
  }
}
</style>
