<template>
  <div class="wrapper">
    <div class="area-container">
      <div class="area-inner">
        <PageStep :step="currentStep" />

        <PageTitle>{{ $t('wish_title_title') }}</PageTitle>
        <div class="sub-heading">{{ $t('wish_subtitle') }}</div>

        <div v-if="swapType === 'oneTwo'" class="subtitle">
          {{ $t('wish_title_wish_index', { wishIndex: wishIndex }) }}
        </div>
        <BasePicker
          :ref="!minRooms ? 'hasErrorMinRooms' : ''"
          key="min-rooms-picker"
          :title="$t('rooms_picker_title')"
          :options="$country.getValue('MIN_ROOMS')"
          :value="minRooms"
          :valid="!showErrors || !!minRooms"
          @selected="handleSetWishMinRooms"
        />

        <div class="slider-container">
          <div class="label">{{ $t('rent_picker_title') }}:</div>
          <BaseSlider
            :value="maxRent"
            :custom-steps="$country.getValue('MAX_RENT_SIGNUP')"
            class="base-slider"
            @input="handleSetWishMaxRent"
          />
        </div>

        <SwapTypeSelector v-if="wishIndex === 1" :collapsed="true" />

        <MobileTipBox
          v-if="
            isMobile &&
            (minRooms >= 4 ||
              maxRent === $country.getValue('MAX_RENT_SIGNUP')[0])
          "
          :text="$t('wish_title_mobile_tooltip_text')"
        />

        <MobileTipBox
          :title="$t('swap_type_tooltip_title')"
          :text="$t('swap_type_tooltip_text')"
        />

        <div class="login-container">
          {{ $t('signup_login_text_1') }}<br />
          <router-link :to="$routes.LOGIN" class="login-link">
            {{ $t('signup_login_action_1') }}
          </router-link>
        </div>

        <NextButtonContainer>
          <BaseButton
            class="button-go-to-next-page smaller rounded"
            :icon-file="'chevron-right-white'"
            :icon-last="true"
            :icon-size="20"
            @click="handleNextClick()"
          >
            {{ $t('wish_title_primary_action') }}
          </BaseButton>
        </NextButtonContainer>
      </div>
    </div>
    <div v-if="!isMobile" class="image-container">
      <DesktopTipBox
        :text="$t('wish_title_desktop_tooltip_text')"
        style="margin-bottom: 16px"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PageTitle from '@/components/Signup/PageTitle';
import MobileTipBox from '@/components/Signup/MobileTipBox.vue';
import DesktopTipBox from '@/components/Signup/DesktopTipBox.vue';
import SwapTypeSelector from '@/components/Signup/SwapTypeSelector.vue';
import PageStep from '@/components/Signup/PageStep.vue';
import NextButtonContainer from '@/components/Signup/NextButtonContainer.vue';
import { calculateProgress } from '@/utils/progress';
import { CURRENT_STEPS } from '@/store/modules/signup';
import events from '@/utils/events';

export default {
  name: 'Wish',

  components: {
    PageTitle,
    MobileTipBox,
    DesktopTipBox,
    PageStep,
    SwapTypeSelector,
    NextButtonContainer
  },

  data() {
    return {
      wishIndex: 1,
      CURRENT_STEPS,
      showErrors: false
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'screenSize/isMobile',
      swapType: 'signup/swapType',
      wishes: 'signup/wishes',
      currentStep: 'signup/currentStep'
    }),

    currentWish() {
      return this.wishes[parseInt(this.wishIndex, 10) - 1];
    },

    canContinue() {
      const { minRooms, maxRent, hasValue } = this;

      return hasValue(minRooms) && hasValue(maxRent);
    },

    minRooms() {
      if (!this.currentWish) return null;
      return this.currentWish.minRooms;
    },

    maxRent() {
      if (!this.currentWish) return null;
      return this.currentWish.maxRent;
    }
  },

  watch: {
    $route() {
      const { index } = this.$route.params;

      this.wishIndex = parseInt(index) || 1;
    },

    canContinue() {
      this.showErrors = false;
    }
  },

  beforeCreate() {
    if (this.wishIndex !== 2) {
      events.emitEvent(events.eventTypes.PAGEVIEW, 'Signup Started', {
        referrer: document.referrer,
        search: document.location ? document.location.search : undefined
      });
    }
  },

  mounted() {
    const { index } = this.$route.params;
    this.wishIndex = parseInt(index) || 1;
    this.setCurrentStep({ step: CURRENT_STEPS.WISH });
    this.scrollToTop();
    this.handleSetWishMinSize(0);

    const maxRent = this.wishes[this.wishIndex - 1].maxRent;

    // only set max rent if there is no state
    if (!maxRent) {
      this.handleSetWishMaxRent(
        this.$country.getValue('MAX_RENT_INITIAL_VALUE')
      );
    }

    calculateProgress(this.currentStep, this.swapType, this.wishIndex);

    events.emitEvent(
      events.eventTypes.PAGEVIEW,
      `Signup Wish ${this.wishIndex}`
    );
  },

  methods: {
    ...mapActions({
      setWishMinRooms: 'signup/setWishMinRooms',
      setWishMinSize: 'signup/setWishMinSize',
      setWishMaxRent: 'signup/setWishMaxRent',
      setCurrentStep: 'signup/setCurrentStep',
      setSwapType: 'signup/setSwapType'
    }),

    handleNextClick() {
      if (this.canContinue) {
        this.$router.push(
          `${this.$t('path_wish', this.$routeLocale)}/${
            this.wishIndex
          }${this.$t('path_wish_area', this.$routeLocale)}`
        );
      }

      this.showErrors = true;
      this.scrollToFirstError();
    },

    scrollToFirstError() {
      this.$nextTick(() => {
        const errorRefs = Object.keys(this.$refs)
          .filter(ref => ref.startsWith('hasError'))
          .map(ref => this.$refs[ref])
          .filter(ref => ref !== undefined);

        if (this.showErrors && errorRefs.length > 0) {
          const firstErrorRef = errorRefs[0];
          if (firstErrorRef && firstErrorRef.$el) {
            firstErrorRef.$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
          } else if (firstErrorRef) {
            firstErrorRef.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
          }
        }
      });
    },

    hasValue(val) {
      return val !== undefined && val !== null;
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    },

    handleSetWishMinRooms(rooms) {
      this.setWishMinRooms({ rooms, wishIndex: this.wishIndex - 1 });
    },

    handleSetWishMinSize(size) {
      this.setWishMinSize({ size, wishIndex: this.wishIndex - 1 });
    },

    handleSetWishMaxRent(rent) {
      this.setWishMaxRent({ rent, wishIndex: this.wishIndex - 1 });
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  max-width: 100vw;
  width: 100%;
  margin: 0;
  display: flex;
  padding-bottom: calc(120px + env(safe-area-inset-bottom));
  background-color: #faf9f8;
  min-height: 100vh;

  @media ($desktop) {
    padding: 0;
  }
}

.area-container {
  flex: 4;
  flex-shrink: none;
  display: flex;
  justify-content: center;
}

.area-inner {
  padding: 0 16px;
  width: 100vw;
  max-width: 450px;

  @media ($desktop) {
    padding: 20px;
  }
}

.sub-heading {
  font-size: 15px;
  color: #999;
  margin-top: -22px;
  margin-bottom: 24px;
}

.image-container {
  flex: 6;
  height: calc(100vh - env(safe-area-inset-bottom));
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-position: center;
  background-size: cover;
  background-image: url('@/assets/img/2.jpg');
}

.subtitle {
  margin-top: -12px;
  margin-bottom: 24px;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.8rem;
  margin-top: 32px;
}

.button-go-to-next-page {
  margin-bottom: env(safe-area-inset-bottom);
  margin-left: auto;
  padding: 12px 20px 12px 26px;
  font-size: 16px;
}

.slider-container {
  margin-bottom: 32px;
}

.slider-container .label {
  margin-bottom: 8px;
  font-size: 16px;
  font-style: normal;
  line-height: 150%;
  letter-spacing: -0.176px;
}
</style>
